
  import FacultyDetail from "@/components/FacultyDetail.vue";
  import { Vue, Component } from "vue-property-decorator";

  @Component({
    components: {
      FacultyDetail,
    },
  })
  export default class MichaelCrary extends Vue {
    michael = {
      name: "Michael A. Crary",
      image: require("@/assets/faculty-mike.png"),
      title: "Ph.D, FASHA",
      description:
        "Professor of Speech-Language Pathology and Professor of Medicine at the University of Central Florida. He is Co- Director of the Swallowing Research Laboratory. Dr. Crary’s current clinical and research interests focus on swallowing disorders, specifically improving assessment and treatment strategies. Current research projects include studies of the characteristics of dysphagia and related morbidities in stroke patients, community dwelling older persons, outcomes of dysphagia treatment in patients with head/neck cancer or stroke, and outcomes associated with exercised-based therapy and adjunctive modalities in the treatment of dysphagia in adult patients. He has a long history as a practicing clinician and remains active in patient care. His most recent text is “Dysphagia Management in Adults and Children” with Michael Groher. Dr. Crary is a Fellow of ASHA and has served in the board of SIG 13. He was awarded Honors of the Association in 2016.",
    };
  }
